"use client";

import { HomeIcon, Squares2X2Icon, ChartBarIcon, ChatBubbleLeftIcon, CalendarIcon } from "@heroicons/react/24/solid";
import { cn } from "~/lib/utils";
interface NavBarItemProps {
  indicatorPosition: "top" | "bottom";
  size: "sm" | "base";
  icon: "Home" | "Squares2X2" | "ChartBar" | "ChatBubbleLeft" | "Calendar";
  text: string;
  active: boolean;
}
export const NavBarItem = ({
  indicatorPosition,
  size,
  icon,
  text,
  active
}: NavBarItemProps) => {
  return <div className={cn(["flex h-[51px] grow flex-col flex-nowrap items-center gap-1", size === "sm" ? "min-w-[64px]" : "min-w-[80px]", indicatorPosition === "top" ? "justify-start" : "justify-end"])} data-sentry-component="NavBarItem" data-sentry-source-file="nav-bar-item.tsx">
      {indicatorPosition === "top" && <div className={cn(["h-[2px] w-full rounded", active ? "bg-foreground" : "bg-transparent"])}></div>}

      {icon === "Home" && <HomeIcon className={cn(["h-5", active ? "text-foreground" : "text-foreground/60"])} />}
      {icon === "Squares2X2" && <Squares2X2Icon className={cn(["h-5", active ? "text-foreground" : "text-foreground/60"])} />}
      {icon === "ChartBar" && <ChartBarIcon className={cn(["h-5", active ? "text-foreground" : "text-foreground/60"])} />}
      {icon === "ChatBubbleLeft" && <ChatBubbleLeftIcon className={cn(["h-5", active ? "text-foreground" : "text-foreground/60"])} />}
      {icon === "Calendar" && <CalendarIcon className={cn(["h-5", active ? "text-foreground" : "text-foreground/60"])} />}

      <div className={cn(["text-xs", active ? "text-foreground" : "text-foreground/60"])}>
        {text}
      </div>

      {indicatorPosition === "bottom" && <div className={cn(["h-[2px] w-full rounded", active ? "bg-foreground" : "bg-transparent"])}></div>}
    </div>;
};