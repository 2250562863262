"use client";

import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button } from "~/components/ui/button";
import { StyledLink } from "~/components/ui/styled-link";
export function cookieConsentGiven() {
  const storedConsent = localStorage.getItem("cookie_consent");
  if (!storedConsent) {
    return "undecided";
  }
  return storedConsent;
}
export const CookieBanner = () => {
  const [consentGiven, setConsentGiven] = useState("");
  const variants = {
    hidden: {
      opacity: 0,
      y: 200
    },
    visible: {
      opacity: 1,
      y: 0
    }
  };
  useEffect(() => {
    setConsentGiven(cookieConsentGiven());
  }, []);
  const handleAcceptCookies = () => {
    localStorage.setItem("cookie_consent", "yes");
    setConsentGiven("yes");
  };
  const handleDeclineCookies = () => {
    localStorage.setItem("cookie_consent", "no");
    setConsentGiven("no");
  };
  return <>
      {consentGiven === "undecided" && <motion.div className="fixed inset-x-[16px] bottom-[16px] z-50 mx-auto flex max-w-[1200px] flex-col gap-2 rounded-sm border-[0.5px] bg-background p-10 shadow-lg" variants={variants} initial="hidden" animate="visible" transition={{
      duration: 0.1,
      delay: 0.5,
      type: "spring",
      stiffness: 180
    }}>
          <div className="w-full">
            <div className="text-lg font-semibold">
              Hilf uns, die Website zu verbessern!
            </div>
          </div>
          <div className="flex flex-col items-center justify-start gap-10 md:flex-row">
            <div className="shrink text-sm">
              Diese Website verwendet Cookies. Einige Cookies sind unerlässlich,
              für das Funktionieren der Website, während andere helfen, die
              Website zu verbessern. Neugierig? Schau dir die{" "}
              <StyledLink href="/cookie-policy" className="font-semibold text-primary">
                Cookie-Richtlinie
              </StyledLink>{" "}
              an.
            </div>

            <div className="flex w-full flex-col items-center justify-end gap-2 sm:w-auto sm:flex-row sm:self-end">
              <Button size="default" variant="ghost" className="w-full border-[0.5px] sm:w-fit" onClick={handleDeclineCookies}>
                Nur Notwendige
              </Button>
              <span> </span>
              <Button size="default" variant="default" className="w-full sm:w-fit" onClick={handleAcceptCookies}>
                Ok, Sicher
              </Button>
            </div>
          </div>
        </motion.div>}
    </>;
};