"use client";

import { type FC, type PropsWithChildren, useEffect, useMemo, useState } from "react";
import Compressor from "compressorjs";
import { useDropzone } from "react-dropzone";
import { type Point, type Area } from "react-easy-crop";
import Cropper from "react-easy-crop";
import { toast } from "sonner";
import { Slider } from "~/components/ui/slider";
import { Button } from "~/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "~/components/ui/dialog";
import { cn } from "~/lib/utils";
import getCroppedImg from "./cropImage";
const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  borderWidth: 1,
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  // width: "120px",
  // height: "120px",
  cursor: "pointer",
  overflow: "hidden"
};
const focusedStyle = {};
const acceptStyle = {};
const rejectStyle = {};
export interface InputImageProps extends PropsWithChildren {
  className?: string;
  readOnly?: boolean;
  onChange: (croppedImage: File) => void;
  compress?: {
    maxWidth: number;
    maxHeight: number;
  };
  aspectRatio?: number;
  shape?: "round" | "rect";
  size?: "sm" | "lg";
}
export const InputImage: FC<InputImageProps> = ({
  className,
  onChange,
  children,
  ...props
}) => {
  const [readOnly] = useState(!!props.readOnly);
  const [dialogisOpen, setDialogisOpen] = useState(false);
  const [crop, setCrop] = useState<Point>({
    x: 0,
    y: 0
  });
  const [zoom, setZoom] = useState(1);
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0
  });
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({
    accept: {
      "image/*": []
    },
    maxFiles: 1
  });
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isFocused, isDragAccept, isDragReject]);
  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0]) {
      openFileCropper(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);
  function openFileCropper(acceptedFile: File) {
    new Compressor(acceptedFile, {
      maxWidth: props.compress?.maxWidth ?? 1000,
      maxHeight: props.compress?.maxWidth ?? 1000,
      success(normalizedFile) {
        const file = new FileReader();
        file.onload = () => {
          setPreview(file.result);
          setDialogisOpen(true);
        };
        file.readAsDataURL(normalizedFile);
      },
      error(_error) {
        // console.log(error);
      }
    });
  }
  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  // eslint-disable-next-line
  function onCropComplete(_: Area, croppedAreaPixels: Area) {
    setCroppedAreaPixels(croppedAreaPixels);
  }
  function errorToast(description: string) {
    toast.error(description);
  }
  async function onCropSave() {
    // 1. Get cropped image
    if (!preview) {
      return errorToast("Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.");
    }
    const createdImage = await getCroppedImg(preview, croppedAreaPixels);

    // // Uncomment to download cropped picture from browser
    // const anchor = document.createElement("a");
    // anchor.download = "my-file-name.jpg"; // optional, but you can give the file a name
    // anchor.href = URL.createObjectURL(createdImage);
    // anchor.click(); // ✨ magic!
    // URL.revokeObjectURL(anchor.href);

    onChange(createdImage);
    setDialogisOpen(false);
  }
  return <>
      {!readOnly && <div id="1" className={className} {...getRootProps({
      style
    })}>
          <input {...getInputProps()}></input>
          {children}
        </div>}
      {readOnly && <div className={className} style={{
      ...style,
      cursor: "default"
    }}>
          {children}
        </div>}

      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange} data-sentry-element="Dialog" data-sentry-source-file="input-image.tsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="input-image.tsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="input-image.tsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="input-image.tsx">
              <span className="text-base font-normal">Passe dein Bild an</span>
            </DialogTitle>
            <DialogDescription asChild data-sentry-element="DialogDescription" data-sentry-source-file="input-image.tsx">
              <div className="h-4 w-full border-b border-solid" />
            </DialogDescription>
          </DialogHeader>
          <div className="relative max-w-full">
            <div className={props?.size === "lg" ? "h-96 w-96" : "h-64 w-64"}>
              <Cropper image={preview as string} crop={crop} zoom={zoom} aspect={props.aspectRatio ?? 1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} cropShape={props.shape ?? "round"} data-sentry-element="Cropper" data-sentry-source-file="input-image.tsx" />
            </div>
          </div>
          <DialogFooter className="flex flex-row flex-wrap" data-sentry-element="DialogFooter" data-sentry-source-file="input-image.tsx">
            <div className="controls mt-4 flex w-full flex-row justify-center self-center">
              <Slider defaultValue={[1.3]} min={1} max={3} step={0.1} className={cn("w-[60%]")} onValueChange={value => {
              setZoom(Number(value));
            }} data-sentry-element="Slider" data-sentry-source-file="input-image.tsx" />
            </div>
            <div className="h-4 w-full border-b border-solid" />
            <div className="flex w-full flex-row justify-center pt-4">
              <Button type="button" variant="secondary" className="w-full" onClick={onCropSave} data-sentry-element="Button" data-sentry-source-file="input-image.tsx">
                Zuschneiden und Speichern
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>;
};