import { CommentItem } from "./comment-item";
import { CommentForm } from "./comment-form";
import { type Comment } from "./types";
interface CommentListProps {
  postId: string;
  comments: Comment[];
}
export function CommentList({
  postId,
  comments
}: CommentListProps) {
  return <div className="flex flex-col gap-2" data-sentry-component="CommentList" data-sentry-source-file="comment-list.tsx">
      <CommentForm postId={postId} data-sentry-element="CommentForm" data-sentry-source-file="comment-list.tsx" />
      <div className="space-y-6">
        {comments?.map(comment => <div key={comment.id}>
            <CommentItem comment={{
          ...comment,
          replies: []
        }} />
            <div className="ml-8 mt-2 space-y-3 pl-4">
              {comment.replies?.map(reply => <CommentItem key={reply.id} comment={{
            ...reply,
            replies: []
          }} />)}
            </div>
          </div>)}
      </div>
    </div>;
}