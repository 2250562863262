"use client";

import { useRef, useState } from "react";
import { Button } from "~/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "~/components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import { api } from "~/trpc/react";
import { CommentForm, LoggedOutCommentForm } from "./comment-form";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import { ReactionPicker } from "~/components/domain/reaction/reaction-picker";
import { EditorContentView } from "~/components/ui/editor/editor-content-view";
import { useSession } from "~/lib/hooks/session-provider";
import { CommentEditor } from "./comment-editor";
import { type Editor as TiptapEditor } from "@tiptap/react";
import { type Comment } from "./types";
interface CommentItemProps {
  comment: Comment;
}
export function CommentItem({
  comment
}: CommentItemProps) {
  const editorRef = useRef<TiptapEditor | null>(null);
  const {
    user
  } = useSession();
  const [isReplying, setIsReplying] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editorContent, setEditorContent] = useState<JSON | null>(comment.content ? JSON.parse(comment.content) as JSON : null);
  const {
    permissions
  } = useSession();
  const utils = api.useUtils();
  const deleteComment = api.comment.delete.useMutation({
    onSuccess: () => {
      void utils.comment.getByPostId.invalidate({
        postId: comment.postId
      });
    }
  });
  const updateComment = api.comment.update.useMutation({
    onSuccess: () => {
      setIsEditing(false);
      void utils.comment.getByPostId.invalidate({
        postId: comment.postId
      });
    }
  });
  const handleDelete = () => {
    if (window.confirm("Möchtest du diesen Kommentar wirklich löschen?")) {
      deleteComment.mutate({
        id: comment.id
      });
    }
  };
  const handleUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    if (!editorContent) return;
    updateComment.mutate({
      id: comment.id,
      content: JSON.stringify(editorContent)
    });
  };
  const createdAt = () => {
    const now = new Date();
    // TODO:Fix this error: 68:30  Error: Unsafe argument of type `any` assigned to a parameter of type `string | number | Date`.  @typescript-eslint/no-unsafe-argument
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const created = new Date(comment.createdAt);
    const diffTime = Math.abs(now.getTime() - created.getTime());
    const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    if (diffMinutes < 60) {
      return `${diffMinutes} Minuten`;
    }
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    if (diffHours < 24) {
      return `${diffHours} Stunden`;
    }
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} Tage`;
  };
  const handleOpenEdit = () => {
    setIsEditing(true);
    setIsReplying(false);
  };
  const handleToggleReply = () => {
    setIsReplying(!isReplying);
    setIsEditing(false);
  };
  const canEditOrDelete = user?.id === comment.author.id || permissions?.hasAdminPermissions;
  return <div className="flex gap-2 text-sm" data-sentry-component="CommentItem" data-sentry-source-file="comment-item.tsx">
      <Avatar data-sentry-element="Avatar" data-sentry-source-file="comment-item.tsx">
        <AvatarImage src={comment.author.profilePicture ?? undefined} data-sentry-element="AvatarImage" data-sentry-source-file="comment-item.tsx" />
        <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="comment-item.tsx">{comment.author.firstname[0]}</AvatarFallback>
      </Avatar>
      <div className="w-full">
        <div className="rounded-sm border px-4 py-2">
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between gap-2">
              <span className="font-medium">
                {comment.author.firstname} {comment.author.lastname?.[0]}.
              </span>
              <div className="flex items-center gap-2">
                <span className="text-xs text-muted-foreground">
                  vor {createdAt()}
                </span>
                {canEditOrDelete && <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="sm" className="rounded-full">
                        <MoreHorizontal className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      {user?.id === comment.author.id && <DropdownMenuItem onClick={handleOpenEdit}>
                          Bearbeiten
                        </DropdownMenuItem>}
                      <DropdownMenuItem className="text-destructive" onClick={handleDelete}>
                        Löschen
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>}
              </div>
            </div>

            {isEditing ? <form onSubmit={handleUpdate} className="flex flex-col gap-2">
                <CommentEditor editorRef={editorRef} placeholder="Kommentar bearbeiten..." editorContent={editorContent} setEditorContent={setEditorContent} />
                <Button variant="secondary" size="sm" type="submit" className="rounded">
                  Speichern
                </Button>
              </form> : <EditorContentView content={comment.content} className="overflow-hidden text-sm" />}
          </div>
        </div>
        <div>
          <div className="mt-2 flex gap-2">
            <ReactionPicker ownerId={comment.id} ownerType="comment" data-sentry-element="ReactionPicker" data-sentry-source-file="comment-item.tsx" />
            {!user ? <LoggedOutCommentForm /> : <Button variant="ghost" size="sm" onClick={handleToggleReply} className="rounded-full">
                Antworten
              </Button>}
          </div>

          {isReplying && <div className="mt-2">
              <CommentForm postId={comment.postId} ancestorId={comment.id} onSuccess={() => {
            setIsReplying(false);
          }} />
            </div>}
        </div>
      </div>
    </div>;
}