"use client";

import { useRef, useState } from "react";
import { api } from "~/trpc/react";
import { Button } from "~/components/ui/button";
import { type Editor as TiptapEditor } from "@tiptap/react";
import { useSession } from "~/lib/hooks/session-provider";
import { CommentEditor } from "./comment-editor";
import { cn } from "~/lib/utils";
import { useLoginModal } from "~/lib/hooks/useLoginModal";
interface CommentFormProps {
  postId: string;
  ancestorId?: string;
  onSuccess?: () => void;
}
export function LoggedOutCommentForm() {
  const {
    onOpen
  } = useLoginModal();
  return <Button variant="ghost" size="sm" className="rounded-full" onClick={() => onOpen()} data-sentry-element="Button" data-sentry-component="LoggedOutCommentForm" data-sentry-source-file="comment-form.tsx">
      Kommentieren
    </Button>;
}
export function CommentForm({
  postId,
  ancestorId,
  onSuccess
}: CommentFormProps) {
  const {
    user
  } = useSession();
  if (!user) {
    return <div className="w-fit">
        <LoggedOutCommentForm />
      </div>;
  }
  return <LoggedInCommentForm postId={postId} ancestorId={ancestorId} onSuccess={onSuccess} data-sentry-element="LoggedInCommentForm" data-sentry-component="CommentForm" data-sentry-source-file="comment-form.tsx" />;
}
export function LoggedInCommentForm({
  postId,
  ancestorId,
  onSuccess
}: CommentFormProps) {
  const editorRef = useRef<TiptapEditor | null>(null);
  const utils = api.useUtils();
  const [editorContent, setEditorContent] = useState<JSON | null>(null);
  const createComment = api.comment.create.useMutation({
    onSuccess: async () => {
      await utils.comment.getByPostId.invalidate({
        postId
      });
      onSuccess?.();
      if (editorRef.current) {
        editorRef.current.commands.clearContent(true);
      }
    }
  });
  return <form onSubmit={async e => {
    e.preventDefault();
    if (createComment.isPending) return;
    if (!editorContent) return;
    try {
      await createComment.mutateAsync({
        postId,
        content: JSON.stringify(editorContent),
        ancestorId
      });
    } catch (error) {
      console.error("Failed to create comment:", error);
    }
  }} className={cn("space-y-2", createComment.isPending && "pointer-events-none opacity-50")} data-sentry-component="LoggedInCommentForm" data-sentry-source-file="comment-form.tsx">
      <CommentEditor placeholder="Kommentar hinzufügen..." editorRef={editorRef} editorContent={editorContent} setEditorContent={setEditorContent} data-sentry-element="CommentEditor" data-sentry-source-file="comment-form.tsx" />
      <Button size="sm" type="submit" disabled={createComment.isPending || !editorContent} className="rounded-full" data-sentry-element="Button" data-sentry-source-file="comment-form.tsx">
        {createComment.isPending ? "Wird gesendet..." : "Senden"}
      </Button>
    </form>;
}