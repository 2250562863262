import { type Editor as TiptapEditor } from "@tiptap/react";
import { Editor } from "~/components/ui/editor";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
export function CommentEditor({
  placeholder,
  editorContent,
  setEditorContent,
  editorRef
}: {
  placeholder: string;
  editorContent: JSON | null;
  setEditorContent: (content: JSON | null) => void;
  editorRef: React.RefObject<TiptapEditor | null>;
}) {
  return <Editor extensions={[Document, Paragraph, Text]} placeholder={placeholder} editorRef={editorRef} content={editorContent} showBubbleMenu={false} showSlashMenu={false} linkPreview={false} allowImageUpload={false} onUpdate={setEditorContent} className="min-h-[80px] rounded-sm border-[0.5px] border-solid border-border p-2" data-sentry-element="Editor" data-sentry-component="CommentEditor" data-sentry-source-file="comment-editor.tsx" />;
}