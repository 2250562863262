"use client";

import { createContext, useContext, useState, type ReactNode } from "react";
interface LoginModalContextType {
  isOpen: boolean;
  returnUrl: string | null;
  onOpen: (returnUrl?: string) => void;
  onClose: () => void;
}
const LoginModalContext = createContext<LoginModalContextType | null>(null);
interface LoginModalProviderProps {
  children: ReactNode;
  defaultReturnUrl: string;
}
export function LoginModalProvider({
  children,
  defaultReturnUrl
}: LoginModalProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [returnUrl, setReturnUrl] = useState<string | null>(null);
  const onOpen = (url?: string) => {
    setIsOpen(true);
    setReturnUrl(url ?? defaultReturnUrl);
  };
  const onClose = () => {
    setIsOpen(false);
    setReturnUrl(null);
  };
  return <LoginModalContext.Provider value={{
    isOpen,
    returnUrl,
    onOpen,
    onClose
  }} data-sentry-element="unknown" data-sentry-component="LoginModalProvider" data-sentry-source-file="useLoginModal.tsx">
      {children}
    </LoginModalContext.Provider>;
}
export function useLoginModal() {
  const context = useContext(LoginModalContext);
  if (!context) {
    throw new Error("useLoginModal must be used within LoginModalProvider");
  }
  return context;
}