"use client";

import { Dialog, DialogContent, DialogDescription, DialogTitle } from "~/components/ui/dialog";
import { useLoginModal } from "~/lib/hooks/useLoginModal";
import { SignInForm } from "~/app/[tenant]/(auth)/signin/signin-form";
import { StyledLink } from "~/components/ui/styled-link";
export function LoginModal({
  organizationId
}: {
  organizationId: string;
}) {
  const {
    isOpen,
    onClose,
    returnUrl
  } = useLoginModal();
  return <Dialog open={isOpen} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="LoginModal" data-sentry-source-file="login-modal.tsx">
      <DialogContent className="sm:max-w-[425px]" data-sentry-element="DialogContent" data-sentry-source-file="login-modal.tsx">
        <div className="grid gap-6">
          <div className="flex flex-col space-y-2 text-center">
            <DialogTitle className="text-2xl font-semibold tracking-tight" data-sentry-element="DialogTitle" data-sentry-source-file="login-modal.tsx">
              Anmelden
            </DialogTitle>
            <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="login-modal.tsx">
              Melde dich an um weitere Funktionen zu nutzen.
            </DialogDescription>
          </div>

          <SignInForm organizationId={organizationId} returnToUrl={returnUrl} data-sentry-element="SignInForm" data-sentry-source-file="login-modal.tsx" />

          <div className="flex w-full max-w-md grow flex-row items-start justify-center">
            <div className="text-xs text-foreground/80">
              Neu auf der Plattform?{" "}
              <StyledLink href="/signup" className="font-semibold text-primary" data-sentry-element="StyledLink" data-sentry-source-file="login-modal.tsx">
                Erstellen Sie einen Account
              </StyledLink>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>;
}