"use client";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { env } from "~/env";
import { cookieConsentGiven } from "~/components/ui/cookie-banner";
if (typeof window !== "undefined") {
  if (!env.NEXT_PUBLIC_POSTHOG_KEY) {
    console.warn("PostHog API Key not set. Analytics disabled.");
  } else {
    posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: "/ingest",
      ui_host: "https://eu.posthog.com",
      persistence: cookieConsentGiven() === "yes" ? "localStorage+cookie" : "memory",
      person_profiles: "always",
      capture_pageview: false,
      // Disable automatic pageview capture, as we capture manually
      capture_pageleave: true
    });
  }
}
export function PHProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="PHProvider" data-sentry-source-file="posthog-provider.tsx">{children}</PostHogProvider>;
}