import { type FC } from "react";
import { type Editor, type Range } from "@tiptap/core";
import { TextSelection } from "@tiptap/pm/state";
import { FaCircleInfo } from "react-icons/fa6";
import { CommandItem } from "~/components/ui/command";
import { cn } from "~/lib/utils";
import { type EditorCommand } from "./command-model";
const displayValue = "Callout einfügen";
const icon = <FaCircleInfo className="h-2.5 w-2.5" />;
interface EditorCommandAddCalloutComponentProps {
  editor: Editor;
  range: Range;
}
const EditorCommandAddCalloutComponent: FC<EditorCommandAddCalloutComponentProps> = ({
  editor,
  range
}) => {
  async function handleAddCallout() {
    editor.chain().focus().deleteRange(range).insertContent({
      type: "editorCalloutNode",
      content: [{
        type: "text",
        text: "Schreibe etwas..."
      }]
    }).command(({
      tr,
      dispatch
    }) => {
      if (dispatch) {
        const pos = tr.selection.$from.end();
        tr.setSelection(TextSelection.create(tr.doc, pos));
      }
      return true;
    }).run();
  }
  return <div onClick={handleAddCallout} data-sentry-component="EditorCommandAddCalloutComponent" data-sentry-source-file="command-add-callout.tsx">
      <CommandItem className={cn(["flex flex-row items-center justify-start gap-2"])} data-sentry-element="CommandItem" data-sentry-source-file="command-add-callout.tsx">
        {icon}
        {displayValue}
      </CommandItem>
    </div>;
};
export const editorCommandAddCallout: EditorCommand = {
  id: "addCallout",
  displayValue: displayValue,
  icon: icon,
  isActive: editor => {
    return editor.isActive("editorCalloutNode");
  },
  run: () => {
    // let the injected component handle the command
  },
  render: ({
    editor,
    range
  }) => {
    return <EditorCommandAddCalloutComponent editor={editor} range={range} />;
  }
};