"use client";

import { useRef, type FC } from "react";
import { type Editor } from "@tiptap/react";
import { cn } from "~/lib/utils";
import { FaFaceGrin } from "react-icons/fa6";
import { Popover, PopoverPortalContent, PopoverTrigger } from "~/components/ui/popover";
import { Button } from "~/components/ui/button";
import { type Emoji, EmojiPicker } from "~/components/ui/emoji-picker";
interface EditorActionButtonEmojiProps {
  editor: Editor;
  className?: string;
}
export const EditorActionButtonEmoji: FC<EditorActionButtonEmojiProps> = ({
  editor,
  className
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  function handleInsertEmoji(emoji: Emoji) {
    editor.chain().focus().insertContent(emoji.native).run();
  }
  return <div ref={ref} data-sentry-component="EditorActionButtonEmoji" data-sentry-source-file="editor-action-button-emoji.tsx">
      <Popover data-sentry-element="Popover" data-sentry-source-file="editor-action-button-emoji.tsx">
        <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="editor-action-button-emoji.tsx">
          <Button variant="ghost" size="icon" title="Fett" className={cn(["h-8 w-8", className])} data-sentry-element="Button" data-sentry-source-file="editor-action-button-emoji.tsx">
            <FaFaceGrin className="h-2/5 w-2/5" data-sentry-element="FaFaceGrin" data-sentry-source-file="editor-action-button-emoji.tsx" />
          </Button>
        </PopoverTrigger>
        <PopoverPortalContent className="relative h-fit w-fit border-none bg-transparent p-0" container={ref.current ?? undefined} data-sentry-element="PopoverPortalContent" data-sentry-source-file="editor-action-button-emoji.tsx">
          <EmojiPicker onEmojiSelect={handleInsertEmoji} data-sentry-element="EmojiPicker" data-sentry-source-file="editor-action-button-emoji.tsx" />
        </PopoverPortalContent>
      </Popover>
    </div>;
};