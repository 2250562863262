"use client";

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { Button } from "~/components/ui/button";
import { StyledLink } from "~/components/ui/styled-link";
import { LoadingDots } from "~/components/ui/loading-dots";
import { api } from "~/trpc/react";
import { RequestVerificationLink } from "../_components/request-verification-link";
import { isValidUrl } from "~/lib/utils/isValidUrl";
const formSchema = z.object({
  email: z.string().email({
    message: "Bitte geben Sie eine gültige E-Mail-Adresse an."
  }),
  password: z.string().min(1, "Bitte geben Sie ein Passwort an.")
});
export interface SignInFormProps {
  organizationId: string;
  returnToUrl?: string | null;
}
export const SignInForm = ({
  organizationId,
  returnToUrl
}: SignInFormProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showRequestVerificationLink, setShowRequestVerificationLink] = useState<boolean>(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: ""
    }
  });
  const authenticateUserMutation = api.auth.authenticateUser.useMutation({
    onSuccess: ({
      token
    }) => {
      const redirectURL = returnToUrl && isValidUrl(returnToUrl, window.location.origin) ? `/auth/${token}?redirectUrl=${returnToUrl}` : `/auth/${token}`;
      window.location.href = redirectURL;
    },
    onError: error => {
      if (error.shape?.data?.code === "UNAUTHORIZED") {
        const message = error.shape?.message;
        if (message === "User not verified") {
          setErrorMessage("Die E-Mail-Adresse ist bereits registriert. Der Account ist jedoch noch nicht verifiziert.");
          setShowRequestVerificationLink(true);
          return;
        }
        if (message === "Incorrect password") {
          form.setError("password", {
            type: "manual",
            message: "Inkorrekte E-Mail oder Passwort."
          });
          return;
        }
      }
      if (error.shape?.data?.code === "NOT_FOUND") {
        const message = error.shape?.message;
        if (message === "Incorrect email or password") {
          form.setError("password", {
            type: "manual",
            message: "Inkorrekte E-Mail oder Passwort."
          });
          return;
        }
      }
      if (error.shape?.data.zodError?.fieldErrors) {
        const fieldErrors = error.shape?.data.zodError.fieldErrors;
        for (const [key, value] of Object.entries(fieldErrors)) {
          const message = value ? value[0] : undefined;
          form.setError(key as keyof z.infer<typeof formSchema>, {
            type: "manual",
            message: message
          });
        }
        return;
      }
      setErrorMessage("Leider ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
      toast.error("Leider ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
    }
  });
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    if (authenticateUserMutation.isPending) {
      return;
    }
    setErrorMessage(null);
    setSuccessMessage(null);
    authenticateUserMutation.mutate({
      email: values.email,
      password: values.password,
      organizationId: organizationId
    });
  }
  return <Form {...form} data-sentry-element="Form" data-sentry-component="SignInForm" data-sentry-source-file="signin-form.tsx">
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormField control={form.control} name="email" render={({
        field
      }) => <FormItem className="space-y-0">
              <FormLabel>E-Mail</FormLabel>
              <FormControl>
                <Input type="email" {...field} autoComplete="email" />
              </FormControl>
              <FormMessage></FormMessage>
            </FormItem>} data-sentry-element="FormField" data-sentry-source-file="signin-form.tsx" />

        <FormField control={form.control} name="password" render={({
        field
      }) => <FormItem className="space-y-0">
              <FormLabel>Passwort</FormLabel>
              <FormControl>
                <Input type="password" {...field} autoComplete="new-password" />
              </FormControl>
              <FormMessage></FormMessage>
            </FormItem>} data-sentry-element="FormField" data-sentry-source-file="signin-form.tsx" />

        <div className="flex w-full flex-row justify-start pt-1 text-xs">
          <StyledLink href="/request-password-reset" className="font-semibold text-primary" data-sentry-element="StyledLink" data-sentry-source-file="signin-form.tsx">
            Passwort vergessen?
          </StyledLink>
        </div>

        <div className="py-4 text-xs text-foreground/80">
          Durch klicken auf &quot;Anmelden&quot;stimmen Sie der den{" "}
          <StyledLink href="/terms-and-services" className="font-semibold text-primary" data-sentry-element="StyledLink" data-sentry-source-file="signin-form.tsx">
            Allgemeinen Geschäftsbedingungen
          </StyledLink>
          , der{" "}
          <StyledLink href="/privacy-policy" className="font-semibold text-primary" data-sentry-element="StyledLink" data-sentry-source-file="signin-form.tsx">
            Datenschutzrichtlinie
          </StyledLink>{" "}
          und der{" "}
          <StyledLink href="/cookie-policy" className="font-semibold text-primary" data-sentry-element="StyledLink" data-sentry-source-file="signin-form.tsx">
            Cookie-Richtlinie
          </StyledLink>{" "}
          zu.
        </div>

        {errorMessage && <>
            <div className="pb-4 text-xs font-semibold text-red-600">
              {errorMessage}
            </div>

            {showRequestVerificationLink && <RequestVerificationLink email={form.getValues("email")} organizationId={organizationId} />}
          </>}

        {successMessage && <div className="pb-4 text-xs font-semibold text-primary">
            {successMessage}
          </div>}

        <Button type="submit" size="sm" variant="ghost" className="w-full rounded-full border-[0.5px] bg-[#FCFAF8]" disabled={authenticateUserMutation.isPending} data-sentry-element="Button" data-sentry-source-file="signin-form.tsx">
          {authenticateUserMutation.isPending && <LoadingDots />}
          {!authenticateUserMutation.isPending && <>Anmelden</>}
        </Button>
      </form>
    </Form>;
};