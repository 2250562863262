"use client";

import { createContext, useContext, type ReactNode } from "react";
import { api } from "~/trpc/react";
import { type FeatureFlag } from "../constants/feature-flags";
interface FeatureFlagContextType {
  flags: Record<FeatureFlag, boolean>;
  isLoading: boolean;
}
const FeatureFlagContext = createContext<FeatureFlagContextType>({
  flags: {} as Record<FeatureFlag, boolean>,
  isLoading: true
});
export function FeatureFlagProvider({
  children
}: {
  children: ReactNode;
}) {
  const {
    data: flags,
    isLoading
  } = api.featureFlag.getAllFeatureFlags.useQuery(undefined, {
    staleTime: 30000 // Cache for 30 seconds
  });
  return <FeatureFlagContext.Provider value={{
    flags: flags ?? {} as Record<FeatureFlag, boolean>,
    isLoading
  }} data-sentry-element="unknown" data-sentry-component="FeatureFlagProvider" data-sentry-source-file="feature-flag-provider.tsx">
      {children}
    </FeatureFlagContext.Provider>;
}
export function useFeatureFlag(featureName: FeatureFlag): boolean {
  const {
    flags
  } = useContext(FeatureFlagContext);
  return flags[featureName] ?? false;
}