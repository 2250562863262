import { default as EmojiData } from "@emoji-mart/data";
import { default as EmojiPickerMart } from "@emoji-mart/react";
export type Emoji = {
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
};
export const EmojiPicker = ({
  onEmojiSelect
}: {
  onEmojiSelect: (emoji: Emoji) => void;
}) => {
  return <EmojiPickerMart data={EmojiData} onEmojiSelect={onEmojiSelect} locale="de" data-sentry-element="EmojiPickerMart" data-sentry-component="EmojiPicker" data-sentry-source-file="emoji-picker.tsx" />;
};