"use client";

import { useState, type FC } from "react";
import { z } from "zod";
import { cn } from "~/lib/utils";
import { HiLink, HiXMark } from "react-icons/hi2";
import { Button } from "~/components/ui/button";
import { Switch } from "~/components/ui/switch";
import { Input } from "~/components/ui/input";
import { Textarea } from "~/components/ui/textarea";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "~/components/ui/dialog";
import { IconSelect, IconView } from "~/components/ui/icon-select";
import { EditableProperty } from "~/components/ui/editable-property";
import { useWidget } from "../../../client/hooks";
import { type NavigationCardWidget } from "../../../model/widget-model";
import { type LinkWithIconConfiguration } from "~/server/db/jsonSchemas/widgetConfiguration";
export interface LinkItemWithIconEditProps {
  link: LinkWithIconConfiguration;
  idx: number;
}
export const LinkItemWithIconEdit: FC<LinkItemWithIconEditProps> = ({
  link,
  idx
}) => {
  const {
    editedWidget,
    setEditedWidget
  } = useWidget<NavigationCardWidget>();
  const [dialogisOpen, setDialogisOpen] = useState(false);
  const [href, setHref] = useState(link.link.href);
  const [target, setTarget] = useState(link.link.target ?? "_self");
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const setLinkItem = async (linkItem: LinkWithIconConfiguration) => {
    const newLinkList = [...editedWidget.configuration.linkList];
    newLinkList[idx] = linkItem;
    await setEditedWidget({
      ...editedWidget,
      configuration: {
        ...editedWidget.configuration,
        linkList: newLinkList
      }
    });
  };
  const handleDeleteLink = async () => {
    setIsDeleteLoading(true);
    const updatedLinkList = [...editedWidget.configuration.linkList.filter((_l, i) => i !== idx)];
    try {
      await setEditedWidget({
        ...editedWidget,
        configuration: {
          ...editedWidget.configuration,
          linkList: updatedLinkList
        }
      });
    } finally {
      setIsDeleteLoading(false);
    }
  };
  return <>
      <div className="group relative col-span-1 flex flex-col items-start justify-start gap-2 text-foreground/80 hover:text-foreground">
        <div className="pointer-events-none absolute left-0 right-0 top-0 z-10 hidden w-full flex-row items-center justify-end gap-1 transition-all group-hover:flex">
          <Button title="Link anpassen" className="pointer-events-auto h-6 bg-foreground/80 p-1 text-xs text-background hover:bg-foreground hover:text-background" onClick={() => setDialogisOpen(true)} data-sentry-element="Button" data-sentry-source-file="link-item-with-icon-edit.tsx">
            <HiLink className="h-3 w-3" data-sentry-element="HiLink" data-sentry-source-file="link-item-with-icon-edit.tsx" />
            <span className="sr-only">Link anpassen</span>
          </Button>

          <Button title="Link entfernen" className="pointer-events-auto h-6 bg-foreground/80 p-1 text-xs text-background hover:bg-foreground hover:text-background" onClick={handleDeleteLink} disabled={isDeleteLoading} data-sentry-element="Button" data-sentry-source-file="link-item-with-icon-edit.tsx">
            <HiXMark className="h-3 w-3" data-sentry-element="HiXMark" data-sentry-source-file="link-item-with-icon-edit.tsx" />
            <span className="sr-only">Link entfernen</span>
          </Button>
        </div>

        <div className="flex flex-row items-center gap-1">
          <EditableProperty data-sentry-element="EditableProperty" data-sentry-source-file="link-item-with-icon-edit.tsx">
            {isActive => {
            return <IconSelect value={link.icon ?? null} onChange={async iconId => {
              await setLinkItem({
                ...link,
                icon: iconId
              });
            }} asChild={true}>
                  <Button variant="ghost" className={cn(["flex h-10 w-10 items-center justify-center rounded border-none p-0", isActive ? "hover:bg-muted" : "hover:bg-muted"])}>
                    {link.icon && <IconView id={link.icon} className="h-5 w-5" />}
                    {!link.icon && <IconView id="HiLink" className="h-5 w-5" />}
                  </Button>
                </IconSelect>;
          }}
          </EditableProperty>

          <EditableProperty data-sentry-element="EditableProperty" data-sentry-source-file="link-item-with-icon-edit.tsx">
            {isActive => {
            return <Input className={cn(["border-none text-sm font-medium", isActive ? "hover:bg-muted" : "hover:bg-muted"])} placeholder="Titel" value={link.title} onChange={async e => {
              await setLinkItem({
                ...link,
                title: e.currentTarget.value
              });
            }} />;
          }}
          </EditableProperty>
        </div>

        <EditableProperty className={() => "w-full"} data-sentry-element="EditableProperty" data-sentry-source-file="link-item-with-icon-edit.tsx">
          {isActive => {
          return <Textarea className={cn(["flex resize-none flex-row items-center gap-1 break-words border-none px-2 text-xs text-foreground/80", isActive ? "hover:bg-muted" : "hover:bg-muted"])} placeholder="Beschreibung" value={link.description} onChange={async e => {
            await setLinkItem({
              ...link,
              description: e.currentTarget.value
            });
          }} />;
        }}
        </EditableProperty>
      </div>

      <Dialog open={dialogisOpen} onOpenChange={setDialogisOpen} data-sentry-element="Dialog" data-sentry-source-file="link-item-with-icon-edit.tsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="link-item-with-icon-edit.tsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="link-item-with-icon-edit.tsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="link-item-with-icon-edit.tsx">Wohin soll verlinkt werden?</DialogTitle>
            <DialogDescription className="sr-only" data-sentry-element="DialogDescription" data-sentry-source-file="link-item-with-icon-edit.tsx">
              Mit diesem Dialog kann die Zieladresse eines Links gesetzt werden.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-4 font-semibold">Zieladresse</div>
          <div className="text-sm text-muted-foreground">
            Bitte geben sie die gesamte Web-Adresse inklusive https:// an. z.B.{" "}
            <span className="bg-muted p-1">https://www.muenchen.de</span>
          </div>
          <Input value={href} placeholder="https://www.muenchen.de" onChange={e => {
          setHref(e.currentTarget.value);
        }} data-sentry-element="Input" data-sentry-source-file="link-item-with-icon-edit.tsx"></Input>

          <div className="flex flex-row items-center justify-start gap-2 text-sm">
            <Switch checked={target === "_blank"} onCheckedChange={checked => {
            setTarget(checked ? "_blank" : "_self");
          }} data-sentry-element="Switch" data-sentry-source-file="link-item-with-icon-edit.tsx" />
            <div> In einem neuen Tab öffnen?</div>
          </div>
          <DialogFooter data-sentry-element="DialogFooter" data-sentry-source-file="link-item-with-icon-edit.tsx">
            <Button variant="ghost" className="border-[0.5px]" onClick={async () => {
            const validated = z.string().url().safeParse(href);
            if (!validated.success) {
              alert("Bitte geben Sie eine gütlige URL ein.");
              return;
            }
            await setLinkItem({
              ...link,
              link: {
                href: validated.data,
                target: target
              }
            });
            setDialogisOpen(false);
          }} data-sentry-element="Button" data-sentry-source-file="link-item-with-icon-edit.tsx">
              Speichern
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>;
};