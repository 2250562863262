"use client";

import React, { type FC, type PropsWithChildren, createContext, useContext, useState, useEffect } from "react";
import { type ModuleConfiguration } from "../../model/module-configuration-model";
import { useUpdateModuleConfiguration } from "./useUpdateModuleConfiguration";
import { api } from "~/trpc/react";
interface ModuleConfigurationContextType {
  moduleKey: string;
  moduleConfiguration: ModuleConfiguration;
  setModuleConfiguration: (moduleConfiguration: ModuleConfiguration) => void;
  editedModuleConfiguration: ModuleConfiguration;
  setEditedModuleConfiguration: (moduleConfiguration: ModuleConfiguration) => void;
  isDirty: boolean;
}
const ModuleConfigurationContext = createContext<ModuleConfigurationContextType | undefined>(undefined);
export interface ModuleConfigurationProviderProps extends PropsWithChildren {
  moduleKey: string;
  moduleConfiguration: ModuleConfiguration;
}
export const ModuleConfigurationProvider: FC<ModuleConfigurationProviderProps> = ({
  children,
  ...props
}) => {
  const [moduleConfiguration, setModuleConfiguration] = useState<ModuleConfiguration>(props.moduleConfiguration);
  const [editedModuleConfiguration, setEditedModuleConfiguration] = useState<ModuleConfiguration>(props.moduleConfiguration);
  const {
    updateModuleConfiguration
  } = useUpdateModuleConfiguration();
  const isDirty = JSON.stringify(moduleConfiguration) !== JSON.stringify(editedModuleConfiguration);
  const utils = api.useUtils();
  useEffect(() => {
    if (!isDirty) return;
    const saveModuleConfiguration = async () => {
      const result = await updateModuleConfiguration({
        module: props.moduleKey,
        id: editedModuleConfiguration.id,
        configuration: editedModuleConfiguration.configuration
      });
      if (result) {
        setModuleConfiguration(result);
        await utils.moduleConfiguration.invalidate();
      }
    };
    const saveTimeout = setTimeout(() => {
      void saveModuleConfiguration();
    }, 800);
    return () => clearTimeout(saveTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedModuleConfiguration, isDirty, props.moduleKey, utils.moduleConfiguration]);
  return <ModuleConfigurationContext.Provider value={{
    moduleKey: props.moduleKey,
    moduleConfiguration,
    setModuleConfiguration,
    editedModuleConfiguration,
    setEditedModuleConfiguration,
    isDirty
  }} data-sentry-element="unknown" data-sentry-component="ModuleConfigurationProvider" data-sentry-source-file="module-configuration-provider.tsx">
      {children}
    </ModuleConfigurationContext.Provider>;
};
export const useModuleConfiguration = (): ModuleConfigurationContextType => {
  const context = useContext(ModuleConfigurationContext);
  if (context === undefined) {
    throw new Error("useModuleConfiguration must be used within an ModuleConfigurationProvider");
  }
  return context;
};