"use client";

import { useRouter } from "next/navigation";
import { toast } from "sonner";
import { api } from "~/trpc/react";
import { type ModuleConfiguration } from "../../model/module-configuration-model";
type PublishedStatusType = "DRAFT" | "PUBLISHED";
interface UpdateModuleConfigurationProps extends Omit<ModuleConfiguration, "organizationId" | "createdAt" | "updatedAt" | "publishedStatus"> {
  publishedStatus?: PublishedStatusType | undefined;
}
export type UpdateModuleConfigurationMutationType = ReturnType<typeof api.moduleConfiguration.updateModuleConfiguration.useMutation>;
export type UpdateModuleConfigurationType = (props: UpdateModuleConfigurationProps) => Promise<ModuleConfiguration | null>;
export const useUpdateModuleConfiguration = () => {
  const router = useRouter();
  const updateModuleConfigurationMutation = api.moduleConfiguration.updateModuleConfiguration.useMutation();
  const updateModuleConfiguration = async (props: UpdateModuleConfigurationProps): Promise<ModuleConfiguration | null> => {
    const result = await updateModuleConfigurationMutation.mutateAsync({
      ...props
    });
    if (!result) {
      toast.error("Leider ist ein Fehler aufgetreten. Die Seitenkonfiguration konnte nicht aktualisiert werden. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
      return null;
    }
    router.refresh();
    return result;
  };
  return {
    updateModuleConfigurationMutation,
    updateModuleConfiguration
  };
};