import { type FC } from "react";
import { type Editor, type Range } from "@tiptap/core";
import { toast } from "sonner";
import { FaImage } from "react-icons/fa6";
import { CommandItem } from "~/components/ui/command";
import { type FileWithPreview, ImageSelect } from "~/components/ui/image-select";
import { cn } from "~/lib/utils";
import { type EditorCommand } from "./command-model";
import { makeImageFromFile } from "../../useHandleEditorFileUpload/functions/make-image-from-file";
import { useEditorFileUpload } from "../../editor-upload";
const displayValue = "Bild hinzufügen";
const icon = <FaImage className="h-2.5 w-2.5" />;
interface EditorCommandUploadAndInsertImageComponentProps {
  editor: Editor;
  range: Range;
}
const EditorCommandUploadAndInsertImageComponent: FC<EditorCommandUploadAndInsertImageComponentProps> = ({
  editor,
  range
}) => {
  const {
    addFile
  } = useEditorFileUpload();
  async function handleImageSelect(fileList: FileWithPreview[]) {
    const file = fileList[0];
    if (!file) {
      toast.error("Datei konnte nicht geladen werden.");
      return false;
    }

    // ensures the maximum image size is not exceeded
    const makeImageResult = await makeImageFromFile(file);
    if (makeImageResult.error) {
      toast.error(makeImageResult.error);
      return false;
    }

    // First add the file to the EditorUploadProvider
    const fileId = addFile(file);
    // Then pass the fileId as reference to the uploadComponent
    editor.chain().focus().deleteRange(range).insertContent({
      type: "editorUploadNode",
      attrs: {
        fileId: fileId
      }
    }).run();
  }
  return <ImageSelect onChange={handleImageSelect} compress={true} data-sentry-element="ImageSelect" data-sentry-component="EditorCommandUploadAndInsertImageComponent" data-sentry-source-file="command-upload-and-insert-image.tsx">
      <CommandItem className={cn(["flex flex-row items-center justify-start gap-2"])} data-sentry-element="CommandItem" data-sentry-source-file="command-upload-and-insert-image.tsx">
        {icon}
        {displayValue}
      </CommandItem>
    </ImageSelect>;
};
export const editorCommandUploadAndInsertImage: EditorCommand = {
  id: "imageUploadAndInsert",
  displayValue: displayValue,
  icon: icon,
  isActive: editor => {
    return editor.isActive("editorUploadNode");
  },
  run: () => {
    // let the injected component handle the command
  },
  render: ({
    editor,
    range
  }) => {
    return <EditorCommandUploadAndInsertImageComponent editor={editor} range={range} />;
  }
};