"use client";

import { type FC } from "react";
import { type NodeViewProps, NodeViewWrapper, NodeViewContent } from "@tiptap/react";
import { Popover, PopoverPortalContent, PopoverTrigger } from "~/components/ui/popover";
import { Button } from "~/components/ui/button";
import "./editor-callout-node-component.css";
import { EmojiPicker, type Emoji } from "~/components/ui/emoji-picker";
export const EditorCalloutNodeComponent: FC<NodeViewProps> = ({
  ...props
}) => {
  const handleEmojiSelect = (emoji: Emoji) => {
    props.updateAttributes({
      icon: emoji.native
    });
  };
  return <NodeViewWrapper className="editor-callout-node" data-sentry-element="NodeViewWrapper" data-sentry-component="EditorCalloutNodeComponent" data-sentry-source-file="editor-callout-node-component.tsx">
      <Popover data-sentry-element="Popover" data-sentry-source-file="editor-callout-node-component.tsx">
        <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="editor-callout-node-component.tsx">
          <Button variant="ghost" size="icon" title="Fett" className="h-8 w-8 hover:bg-foreground/5" onMouseDown={e => {
          // Prevent editor from setting the cursor position.
          // Instead it will just lose focus.
          e.preventDefault();
        }} data-sentry-element="Button" data-sentry-source-file="editor-callout-node-component.tsx">
            <div className="callout-icon">{props.node.attrs.icon}</div>
          </Button>
        </PopoverTrigger>
        <PopoverPortalContent className="relative h-fit w-fit border-none bg-transparent p-0" onMouseDown={e => {
        // Note: A if a parent component has a click-outside handler, it will be triggered
        // unless we stop the event propagation.
        // For example, if the click-outside handler removes the editor component from
        // the DOM, the selected emoji would not be selected.
        e.stopPropagation();
      }} data-sentry-element="PopoverPortalContent" data-sentry-source-file="editor-callout-node-component.tsx">
          <EmojiPicker onEmojiSelect={handleEmojiSelect} data-sentry-element="EmojiPicker" data-sentry-source-file="editor-callout-node-component.tsx" />
        </PopoverPortalContent>
      </Popover>

      <NodeViewContent className="callout-content content is-editable" data-sentry-element="NodeViewContent" data-sentry-source-file="editor-callout-node-component.tsx" />
    </NodeViewWrapper>;
};