"use client";

import { useRouter } from "next/navigation";
import { toast } from "sonner";
import { api } from "~/trpc/react";
import { type BaseWidget } from "../../model/widget-model";
import { useModuleConfiguration } from "~/components/domain/moduleConfiguration/client/hooks/module-configuration-provider";
import { type Section } from "~/components/layout/types";
interface DeleteWidgetProps {
  id: string;
  section: Section;
}
export type DeleteWidgetMutationType = ReturnType<typeof api.widget.deleteWidget.useMutation>;
export type DeleteWidgetType = (props?: DeleteWidgetProps) => Promise<BaseWidget | null>;
export const useDeleteWidget = () => {
  const router = useRouter();
  const deleteWidgetMutation = api.widget.deleteWidget.useMutation();
  const {
    moduleConfiguration,
    setEditedModuleConfiguration
  } = useModuleConfiguration();
  const deleteWidget = async (props: DeleteWidgetProps) => {
    const {
      id,
      section
    } = props;
    const result = await deleteWidgetMutation.mutateAsync({
      id: id
    });
    if (!result) {
      toast.error("Leider ist ein Fehler aufgetreten. Das Widget konnte nicht gelöscht werden. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
      return null;
    }
    const newWidgetList = moduleConfiguration.configuration[section].widgetList.filter(widget => widget.id !== id);
    const newModuleConfiguration = {
      ...moduleConfiguration,
      configuration: {
        ...moduleConfiguration.configuration,
        [section]: {
          ...moduleConfiguration.configuration[section],
          widgetList: newWidgetList
        }
      }
    };
    setEditedModuleConfiguration(newModuleConfiguration);
    router.refresh();
    return result;
  };
  return {
    deleteWidgetMutation,
    deleteWidget
  };
};