"use client";

import { type ReactNode } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { LoginModal } from "./login-modal";
import { LoginModalProvider as ContextProvider } from "~/lib/hooks/useLoginModal";
interface LoginModalProviderProps {
  children: ReactNode;
  organizationId: string;
}
export function LoginModalProvider({
  children,
  organizationId
}: LoginModalProviderProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // Combine pathname and search params to get full URL
  const currentUrl = searchParams.size > 0 ? `${pathname}?${searchParams.toString()}` : pathname;
  return <ContextProvider defaultReturnUrl={currentUrl} data-sentry-element="ContextProvider" data-sentry-component="LoginModalProvider" data-sentry-source-file="login-modal-provider.tsx">
      <LoginModal organizationId={organizationId} data-sentry-element="LoginModal" data-sentry-source-file="login-modal-provider.tsx" />
      {children}
    </ContextProvider>;
}