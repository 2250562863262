"use client";

import { toast } from "sonner";
import { cn } from "~/lib/utils";
import { api } from "~/trpc/react";
export interface RequestVerificationLinkProps {
  organizationId: string;
  email: string;
}
export const RequestVerificationLink = ({
  email,
  organizationId
}: RequestVerificationLinkProps) => {
  const requestVerificationLinkMutation = api.auth.requestVerificationLink.useMutation({
    onSuccess: () => {
      toast.success("Wir haben Ihnen einen Link zur Verifizierung Ihres Accounts geschickt. Bitte überprüfen Sie Ihre E-Mails und folgen Sie den Anweisungen, um Ihr Account zu verifizieren.");
    },
    onError: () => {
      toast.error("Leider ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
    }
  });
  function handleRequestVerificationLink() {
    if (requestVerificationLinkMutation.isPending || requestVerificationLinkMutation.isSuccess) {
      return;
    }
    requestVerificationLinkMutation.mutate({
      email: email,
      organizationId: organizationId
    });
  }
  return <div className="pb-4 text-xs font-semibold" data-sentry-component="RequestVerificationLink" data-sentry-source-file="request-verification-link.tsx">
      Möchten Sie Ihren Account verifizieren?{" "}
      <div className={cn(["text-primary", requestVerificationLinkMutation.isPending ? "text-muted-foreground" : "", !requestVerificationLinkMutation.isSuccess ? "hover:cursor-pointer hover:underline" : ""])} onClick={() => handleRequestVerificationLink()}>
        {requestVerificationLinkMutation.isSuccess && <>E-Mail zur Verifizierung wurde gesendet</>}
        {!requestVerificationLinkMutation.isSuccess && <>E-Mail zur Verifizierung anfordern</>}
        .
      </div>
    </div>;
};