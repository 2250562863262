"use client";

import { useState, type FC } from "react";
import { z } from "zod";
import { cn } from "~/lib/utils";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Switch } from "~/components/ui/switch";
import { Card, CardContent, CardFooter, CardHeader } from "~/components/ui/card";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "~/components/ui/dialog";
import { HiArrowSmallRight, HiLink } from "react-icons/hi2";
import { EditableProperty } from "~/components/ui/editable-property";
import { useWidget } from "../../../client/hooks";
import { type NavigationCardWidget } from "../../../model/widget-model";
import { LinkItemWithIconEdit } from "./link-item-with-icon-edit";
export interface NavigationCardWidgetEditProps {
  widget: NavigationCardWidget;
}
export const NavigationCardWidgetEdit: FC<NavigationCardWidgetEditProps> = () => {
  const {
    editedWidget,
    setEditedWidget
  } = useWidget<NavigationCardWidget>();
  const [footerDialogOpen, setFooterDialoOpen] = useState<boolean>(false);
  const [footerLinkHref, setFooterLinkHref] = useState(editedWidget.configuration.footerLink?.href ?? "");
  const [footerLinkTarget, setFooterLinkTarget] = useState(editedWidget.configuration.footerLink?.target ?? "_self");
  return <Card className="w-full" data-sentry-element="Card" data-sentry-component="NavigationCardWidgetEdit" data-sentry-source-file="navigation-card-widget-edit.tsx">
      <CardHeader className="flex flex-row items-start justify-between" data-sentry-element="CardHeader" data-sentry-source-file="navigation-card-widget-edit.tsx">
        <div className="text-xl font-semibold">
          <EditableProperty data-sentry-element="EditableProperty" data-sentry-source-file="navigation-card-widget-edit.tsx">
            {isActive => {
            return <Input className={cn(["border-none text-xl font-semibold", isActive ? "hover:bg-muted" : "hover:bg-muted"])} value={editedWidget.configuration.title} onChange={async e => {
              await setEditedWidget({
                ...editedWidget,
                configuration: {
                  ...editedWidget.configuration,
                  title: e.currentTarget.value
                }
              });
            }} />;
          }}
          </EditableProperty>
        </div>
      </CardHeader>
      <CardContent className="grid gap-2" style={{
      gridTemplateColumns: `repeat(${Math.min(editedWidget.configuration.linkList.length + 1, 3)}, minmax(0, 1fr))`
    }} data-sentry-element="CardContent" data-sentry-source-file="navigation-card-widget-edit.tsx">
        {editedWidget.configuration.linkList.map((link, idx) => {
        return <LinkItemWithIconEdit key={idx} link={link} idx={idx} />;
      })}

        {editedWidget.configuration.linkList.length < 3 && <Button variant="ghost" className="col-span-1 flex h-full flex-col items-center justify-center rounded bg-muted p-2" onClick={async () => {
        await setEditedWidget({
          ...editedWidget,
          configuration: {
            ...editedWidget.configuration,
            linkList: [...editedWidget.configuration.linkList, {
              type: "link-with-icon",
              title: "Neuer Link",
              icon: "",
              description: "",
              link: {
                href: "#",
                target: "_self"
              }
            }]
          }
        });
      }}>
            Link hinzufügen
          </Button>}
      </CardContent>

      <CardFooter className="group relative flex flex-row justify-center border-t-[0.5px] pb-2" data-sentry-element="CardFooter" data-sentry-source-file="navigation-card-widget-edit.tsx">
        <div className="pointer-events-none absolute left-0 right-0 top-0 z-10 hidden w-full flex-row items-center justify-end gap-1 px-2 pt-1 transition-all group-hover:flex">
          <Button title="Link anpassen" className="pointer-events-auto h-6 bg-foreground/80 p-1 text-xs text-background hover:bg-foreground hover:text-background" onClick={() => setFooterDialoOpen(true)} data-sentry-element="Button" data-sentry-source-file="navigation-card-widget-edit.tsx">
            <HiLink className="h-3 w-3" data-sentry-element="HiLink" data-sentry-source-file="navigation-card-widget-edit.tsx" />
            <span className="sr-only">Link anpassen</span>
          </Button>
        </div>
        <div className="pt-1">
          <div className="flex flex-row items-center gap-1 text-sm font-medium text-foreground/80 hover:text-foreground">
            <EditableProperty data-sentry-element="EditableProperty" data-sentry-source-file="navigation-card-widget-edit.tsx">
              {isActive => {
              return <Input className={cn(["border-none text-sm font-medium", isActive ? "hover:bg-muted" : "hover:bg-muted"])} placeholder="Weitere Informationen" value={editedWidget.configuration.footerLinkTitle} onChange={async e => {
                await setEditedWidget({
                  ...editedWidget,
                  configuration: {
                    ...editedWidget.configuration,
                    footerLinkTitle: e.currentTarget.value
                  }
                });
              }} />;
            }}
            </EditableProperty>{" "}
            <HiArrowSmallRight data-sentry-element="HiArrowSmallRight" data-sentry-source-file="navigation-card-widget-edit.tsx" />
          </div>
        </div>

        <Dialog open={footerDialogOpen} onOpenChange={setFooterDialoOpen} data-sentry-element="Dialog" data-sentry-source-file="navigation-card-widget-edit.tsx">
          <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="navigation-card-widget-edit.tsx">
            <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="navigation-card-widget-edit.tsx">
              <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="navigation-card-widget-edit.tsx">Wohin soll verlinkt werden?</DialogTitle>
              <DialogDescription className="sr-only" data-sentry-element="DialogDescription" data-sentry-source-file="navigation-card-widget-edit.tsx">
                Mit diesem Dialog kann die Zieladresse eines Links gesetzt
                werden.
              </DialogDescription>
            </DialogHeader>
            <div className="mt-4 font-semibold">Zieladresse</div>
            <div className="text-sm text-muted-foreground">
              Bitte geben sie die gesamte Web-Adresse inklusive https:// an.
              z.B. <span className="bg-muted p-1">https://www.muenchen.de</span>
            </div>
            <Input value={footerLinkHref} placeholder="https://www.muenchen.de" onChange={e => {
            setFooterLinkHref(e.currentTarget.value);
          }} data-sentry-element="Input" data-sentry-source-file="navigation-card-widget-edit.tsx"></Input>

            <div className="flex flex-row items-center justify-start gap-2 text-sm">
              <Switch checked={footerLinkTarget === "_blank"} onCheckedChange={checked => {
              setFooterLinkTarget(checked ? "_blank" : "_self");
            }} data-sentry-element="Switch" data-sentry-source-file="navigation-card-widget-edit.tsx" />
              <div> In einem neuen Tab öffnen?</div>
            </div>
            <DialogFooter data-sentry-element="DialogFooter" data-sentry-source-file="navigation-card-widget-edit.tsx">
              <Button variant="ghost" className="border-[0.5px]" onClick={async () => {
              const validated = z.string().url().safeParse(footerLinkHref);
              if (!validated.success) {
                alert("Bitte geben Sie eine gütlige URL ein.");
                return;
              }
              await setEditedWidget({
                ...editedWidget,
                configuration: {
                  ...editedWidget.configuration,
                  footerLink: {
                    href: footerLinkHref,
                    target: footerLinkTarget
                  }
                }
              });
              setFooterDialoOpen(false);
            }} data-sentry-element="Button" data-sentry-source-file="navigation-card-widget-edit.tsx">
                Speichern
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardFooter>
    </Card>;
};