import { type NodeConfig } from "@tiptap/core";
import { mergeAttributes, Node } from "@tiptap/core";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const editorCalloutNodeExtensionConfig: Partial<NodeConfig<any, any>> = {
  name: "editorCalloutNode",
  group: "block",
  content: "inline*",
  // See: https://tiptap.dev/docs/editor/core-concepts/schema#defining
  defining: true,
  addAttributes() {
    return {
      icon: {
        default: "💡"
      }
    };
  },
  parseHTML() {
    return [{
      tag: "editor-callout-node"
    }];
  },
  renderHTML({
    HTMLAttributes
  }) {
    return ["div", mergeAttributes({
      class: "editor-callout-node"
    }), ["div", {
      class: "callout-icon"
    }, HTMLAttributes.icon || "💡"], ["div", {
      class: "callout-content"
    }, 0 // This '0' is a placeholder where the content will be inserted
    ]];
  }
};
export const EditorCalloutNodeExtensionServer = Node.create(editorCalloutNodeExtensionConfig);