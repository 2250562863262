export const isValidUrl = (url: string | null, baseUrl: string): boolean => {
  if (!url || !baseUrl) return false;
  try {
    // Check if we can parse the url with the url
    new URL(url, baseUrl);
    return true;
  } catch (error) {
    return false;
  }
};
