import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.53.3_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["CommentsReactions"] */ "/app/src/components/domain/comment/comments-reactions.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/feed/feed-new-post-card/feed-new-post-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeedbackForm"] */ "/app/src/components/domain/feedback/feedback-form.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/post/general/post-interaction-dropdown/post-interaction-dropdown.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/post/post-with-image/post-with-image-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/post/post-with-text/post-body-text.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/post/post-with-video/post-body-video.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/widget/widget-view-factory/navigation-card-widget/navigation-card-widget-edit/navigation-card-widget-edit.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/widget/widget-view-factory/organization-hero-widget/organization-hero-widget-edit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/app/src/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/app/src/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/ui/icon-select/icon-select.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/ui/styled-link.tsx");
